import React, { useEffect, useState } from "react";

import { LoadingMessage } from "./LoadingMessage";

interface Props {
  children: React.ReactElement;
}

export const InitializeApp = (props: Props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return loading ? <LoadingMessage /> : props.children;
};
