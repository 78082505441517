import { CODES, KEYS } from "../keys";
import { register } from "./register";
import { trackEvent } from "../analytics";

const searchParams = new URLSearchParams(window.location.search);

export const actionToggleViewMode = register({
  name: "viewMode",
  perform(elements, appState) {
    trackEvent("view", "mode", "view");
    return {
      appState: {
        ...appState,
        viewModeEnabled: searchParams.has('readonly') ? true : !this.checked!(appState),
      },
      commitToHistory: false,
    };
  },
  checked: (appState) => appState.viewModeEnabled,
  contextItemLabel: "labels.viewMode",
  keyTest: (event) =>
    !event[KEYS.CTRL_OR_CMD] && event.altKey && event.code === CODES.R,
});
